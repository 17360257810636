import React from "react";
import classes from "./loader.module.scss";
import loader from "../../../assets/img/Spinner.gif";

export const Loader: React.FC<{
  loaderHeight?: string;
}> = ({ loaderHeight }) => {
  return (
    <div className={classes["loader-container"]} style={{ height: loaderHeight || "calc(100vh - 70px)" }}>
      <img className={classes["main-loader"]} src={loader} alt="Loader Img" />
      <p className={"waitcls"}>Please Wait ...</p>
    </div>
  );
};

export const ButtonLoader: React.FC<{
  loaderHeight?: string;
  loaderText?:string
}> = ({ loaderHeight,loaderText="Please Wait ..." }) => {
  return (
    <div className={classes["button-loader-container"]} style={{ height: loaderHeight || "calc(100vh - 70px)" }}>
      <img className={classes["main-loader"]} src={loader} alt="Loader Img" />
      <p className={"waitcls"}>{loaderText}</p>
    </div>
  );
};