import Cookies from "universal-cookie";
import jwt_decode from "jwt-decode";
export const TokenService = () => {
  const cookies = new Cookies();
  const dateNow = new Date();
  dateNow.setDate(dateNow.getDate() + 7);

  const setToken = (accessToken: string, refreshToken: string) => {
    cookies.set("accessToken", accessToken);
    cookies.set("refreshToken", refreshToken);
  };

  const isAccessTokenExpired = () => {
    const accessToken = getAccessToken();

    if (accessToken) {
      try {
        // const decodedToken: any = jwt_decode(accessToken);
        // if (decodedToken && decodedToken.exp) {
        //     const expirationTime = decodedToken.exp * 1000; // Convert seconds to milliseconds
        //     return expirationTime < Date.now();
        // } else {
        //     return true;
        // }
      } catch (error) {
        console.error("Error decoding access token:", error);
        return true; // Assume expired in case of any error
      }
    }
    return true; // No access token found, consider it as expired
  };

  const getAccessToken = () => {
    return cookies.get("accessToken");
  };

  const getRefreshToken = () => {
    return cookies.get("refreshToken");
  };

  const clearAllToken = () => {
    cookies.remove("accessToken", { path: "/" });
    cookies.remove("refreshToken", { path: "/" });
    cookies.remove("userInfo", { path: "/" });
    cookies.remove("x-origin", { path: "/" });
    cookies.remove("x-domain", { path: "/" });

    cookies.remove("rolesList");
    cookies.remove("currentRole");
    cookies.remove("currentRoleName");
    cookies.remove("currentRoleObject");
    
    localStorage.removeItem("rolesList");
    localStorage.removeItem("currentRole");
    localStorage.removeItem("currentRoleName");
    localStorage.removeItem("currentRoleObject");
  };

  const clearAccessToken = () => {
    cookies.remove("accessToken", { path: "/" });
  };

  const setXDomain = (domain: string) => {
    cookies.set("x-domain", domain, { path: "/" });
  };

  const getXDomain = () => {
    return cookies.get("x-domain");
  };

  const setOrigin = (origin: string) => {
    cookies.set("x-origin", origin, { path: "/" });
  };

  const getOrigin = () => {
    return cookies.get("x-origin");
  };

  const clearOriginDomain = () => {
    cookies.remove("x-domain");
    cookies.remove("x-origin");
  };

  return {
    setToken: setToken,
    getAccessToken: getAccessToken,
    getRefreshToken: getRefreshToken,
    clearAllToken: clearAllToken,
    getDomain: getXDomain,
    setDomain: setXDomain,
    setOrigin: setOrigin,
    getOrigin: getOrigin,
    clearOriginDomain: clearOriginDomain,
    clearAccessToken: clearAccessToken,
    isAccessTokenExpired: isAccessTokenExpired,
  };
};
