import React, { useEffect, useContext, useRef } from "react";
import { OnlineStatusContext } from "../../customHooks/OnlineStatusContext";
import { Toast } from "primereact/toast";

function useNetworkStatusNotifier(): JSX.Element {
    const isOnline = useContext(OnlineStatusContext);

    const toast = useRef<Toast>(null);
    const lastNotificationRef = useRef<boolean | null | undefined>(null); // Tracks last notification state
    const isFirstRender = useRef<boolean>(true); // Tracks if it's the first render

    useEffect(() => {
        if (isFirstRender.current) {
            // Skip the first render
            isFirstRender.current = false;
            lastNotificationRef.current = isOnline; // Initialize lastNotificationRef
            return;
        }

        if ( isOnline !== lastNotificationRef.current) {
           
        }
    }, [isOnline]);

    return <Toast ref={toast} />;
}

export default useNetworkStatusNotifier;
