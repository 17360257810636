import { ROUTE_CONSTANTS } from "../../constants/RouteConstants";
export class NavigateUserService {
  getRoute = (): any => {
    const path = window.location.pathname;
    if (
      path == ROUTE_CONSTANTS.PlANNERWINDOW ||
      path == ROUTE_CONSTANTS.SELLIN ||
      path == ROUTE_CONSTANTS.SELLOUT ||
      path == ROUTE_CONSTANTS.COLLECTIONPLAN ||
      path == ROUTE_CONSTANTS.OPEXPLAN ||
      path == ROUTE_CONSTANTS.ANNUALCOLLECTIONPLAN ||
      path == ROUTE_CONSTANTS.ANNUALOPEX ||
      path == ROUTE_CONSTANTS.ANNUALSALES
    ) {
      return path;
    } else {
      return this.getDefaultRoute();
    }
  };

  getDefaultRoute = (): any => {
    return ROUTE_CONSTANTS.PlANNERWINDOW;
  };
}
